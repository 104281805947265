export const weatherRatings = [
  {
    texts: "sub-arctic",
    blurbs: "It's dangerously cold. Don't go outside.",
    tops: "many layers",
    outers: "heavy winter coat",
    bottoms: "jeans+leggings/long underwear",
    shoes: "insulated boots w. multiple socks",
    accessories: "scarf,hat,gloves,hand warmer",
  },
  {
    texts: "bitter cold",
    blurbs: "It's bitterly cold. Bundle up!",
    tops: "hoodie; sweater; layers",
    outers: "heavy winter coat",
    bottoms: "jeans+leggings; long underwear",
    shoes: "insulated boots w. heavy socks",
    accessories: "scarf, hat, gloves",
  },
  {
    texts: "very cold",
    blurbs: "it's very cold. layer up!",
    tops: "hoodie; sweater; layers",
    outers: "heavy winter coat",
    bottoms: "jeans+leggings; long underwear",
    shoes: "insulated boots",
    accessories: "hat, gloves",
  },
  {
    texts: "cold",
    blurbs: "it's cold, wear a coat!",
    tops: "long sleeves; consider layers",
    outers: "heavy winter coat",
    bottoms: "jeans or jeans+leggings",
    shoes: "snow boots w. multiple socks",
    accessories: "a hat and gloves if you'll be outside",
  },
  {
    texts: "quite cool",
    blurbs: "It's cool, leather-jacket weather.",
    tops: "layers",
    outers: "leather jacket or light winter coat",
    bottoms: "jeans",
    shoes: "closed-toed, warm socks if you'll be standing/sitting",
    accessories: "something with pockets",
  },
  {
    texts: "cool",
    blurbs: "a little cool - like an office",
    tops: "anything",
    outers: "hoodie; light jacket; wrap",
    bottoms: "jeans",
    shoes: "something with socks",
    accessories: "whateva whateva",
  },
  {
    texts: "comfortable",
    blurbs: "a nice comfortable temp",
    tops: "anything",
    outers: "nothing; hoodie",
    bottoms: "anything",
    shoes: "anything",
    accessories: "whateva whateva",
  },
  {
    texts: "warm",
    blurbs: "it's a little warm, you'll be comfy",
    tops: "anything",
    outers: "nothing; maybe a light hoodie",
    bottoms: "jeans; skirt",
    shoes: "anything",
    accessories: "whateva whateva",
  },
  {
    texts: "quite warm",
    blurbs: "Warm but not hot, keep it light!",
    tops: "short sleeves",
    outers: "none",
    bottoms: "shorts, skirt, maybe leggings",
    shoes: "sneakers or sandals",
    accessories: "none",
  },
  {
    texts: "hot",
    blurbs: "It's hot",
    tops: "short sleeves/tank top",
    outers: "none",
    bottoms: "shorts or skirt",
    shoes: "sandals",
    accessories: "none",
  },
  {
    texts: "oppressive heat",
    blurbs: "It's super....hot",
    tops: "short sleeves/tank top",
    outers: "none",
    bottoms: "shorts or skirt",
    shoes: "sandals",
    accessories: "none",
  },
  {
    texts: "dangerously hot",
    blurbs: "It's dangerously hot, stay inside!",
    tops: "short sleeves/tank top",
    outers: "none",
    bottoms: "shorts or skirt",
    shoes: "sandals",
    accessories: "none",
  },
];
