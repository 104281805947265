import React from "react";
import { StyleSheet, Text, Image } from "react-native";

export default function ImageBox(props) {
  const { source } = props;
  return (
    <>
      <Text>The temp is similar to this photo:</Text>
      <Image style={styles.image} source={source} />
    </>
  );
}

const styles = StyleSheet.create({
  image: {
    alignContent: "center",
    marginHorizontal: "auto",
    width: "90%",
    aspectRatio: 1,
    // height: "100px",
    borderColor: "#fff",
    borderWidth: 3,
    // borderBottomWidth: "50px",
    borderRadius: 5,
  },
});
