import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

export default function CurrentConditions(props) {
  const { conditions } = props;
  const [dateObject, setDateObject] = useState(null);

  useEffect(() => {
    console.log(conditions.current.dt);
    setDateObject(new Date(+conditions.current.dt * 1000));
  }, [conditions]);

  return (
    <View style={styles.main}>
      <Text style={styles.header}>Current Conditions:</Text>
      <Text style={styles.time}>
        as of{" "}
        {dateObject
          ? `${dateObject.getMonth() + 1}/${dateObject.getDate()} at ${
              dateObject.getHours() < 12
                ? dateObject.getHours() + ":" + dateObject.getMinutes() + "am"
                : dateObject.getHours() -
                  12 +
                  ":" +
                  dateObject.getMinutes() +
                  "pm"
            }`
          : "something isn't working.."}
      </Text>
      <Text style={styles.conditionDetails}>
        {`${parseInt(conditions.current.temp)} degrees (feels like ${parseInt(
          conditions.current.feels_like
        )})`}
      </Text>
      <Text style={styles.conditionDetails}>
        {conditions.current.weather.map((weather, key) => {
          return `${weather.description}${
            key < conditions.current.weather.length - 1 ? ", " : ""
          }`;
        })}
      </Text>
      {conditions.alerts ? (
        conditions.alerts.map((alert, key) => (
          <Text key={key}>{alert.event}</Text>
        ))
      ) : (
        <Text style={styles.noAlerts}>(no weather alerts)</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ff00ff44",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    fontSize: 30,
    textAlign: "center",
    fontWeight: "bold",
  },
  time: {
    fontSize: 12,
    fontWeight: "200",
  },
  conditionDetails: {
    fontSize: 20,
  },
  noAlerts: {
    color: "#888",
    fontSize: 14,
  },
  main: {
    marginBottom: 10,
  },
});
