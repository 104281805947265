import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  Image,
  ScrollView,
  RefreshControl,
} from "react-native";
import Axios from "axios";
import { firebaseApp } from "../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { weatherRatings } from "../weatherRatings";
import SuggestionsBox from "../components/SuggestionsBox";
import CurrentConditions from "../components/CurrentConditions";
import ImageBox from "../components/ImageBox";

export default function HomeScreen() {
  const [currentWeatherJSON, setCurrentWeatherJSON] = useState({});
  const [currentWeatherRating, setCurrentWeatherRating] = useState(NaN);
  const [imageSource, setImageSource] = useState("");
  const [currentRatingObject, setRatingObject] = useState({});
  const [refreshing, setRefreshing] = useState(false);

  // const wait = (timeout) => {
  //   return new Promise((resolve) => setTimeout(resolve, timeout));
  // };

  const onRefresh = useCallback(async () => {
    console.log(`refreshing view!`);
    setRefreshing(true);
    setCurrentWeatherJSON(
      await Axios({
        method: "get",
        url: "https://api.openweathermap.org/data/2.5/onecall?lat=41.96&lon=-87.71&exclude=minutely,hourly,daily&appid=e84f3cb4c2aca14f25a8e6cc600c82aa&units=imperial",
      })
    ).then(() => setRefreshing(false));
  }, []);

  useEffect(async () => {
    const response = await Axios({
      method: "get",
      url: "https://api.openweathermap.org/data/2.5/onecall?lat=41.96&lon=-87.71&exclude=minutely,hourly,daily&appid=e84f3cb4c2aca14f25a8e6cc600c82aa&units=imperial",
    });
    // console.dir(response.data);
    setCurrentWeatherJSON(response.data);
  }, [refreshing]);

  useEffect(() => {
    if (!currentWeatherJSON.current) return false;
    if (currentWeatherJSON.current.temp < 0) setCurrentWeatherRating(0);
    else if (currentWeatherJSON.current.temp < 20) setCurrentWeatherRating(1);
    else if (currentWeatherJSON.current.temp < 35) setCurrentWeatherRating(2);
    else if (currentWeatherJSON.current.temp < 50) setCurrentWeatherRating(3);
    else if (currentWeatherJSON.current.temp < 64) setCurrentWeatherRating(4);
    else if (currentWeatherJSON.current.temp < 72) setCurrentWeatherRating(5);
    else if (currentWeatherJSON.current.temp < 77) setCurrentWeatherRating(6);
    else if (currentWeatherJSON.current.temp < 83) setCurrentWeatherRating(7);
    else if (currentWeatherJSON.current.temp < 88) setCurrentWeatherRating(8);
    else if (currentWeatherJSON.current.temp < 92) setCurrentWeatherRating(9);
    else if (currentWeatherJSON.current.temp < 99) setCurrentWeatherRating(10);
    else setCurrentWeatherRating(11);
  }, [currentWeatherJSON]);

  useEffect(() => {
    setRatingObject(weatherRatings[currentWeatherRating]);
  }, [currentWeatherRating]);

  useEffect(async () => {
    if (!currentWeatherRating) return false;
    const storage = getStorage();
    getDownloadURL(
      ref(
        storage,
        `gs://clothes-picker.appspot.com/clothes-photos/${currentWeatherRating}a.jpg`
      )
    ).then((url) => {
      setImageSource(url);
    });
  }, [currentWeatherRating]);

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {currentWeatherJSON.current ? (
          <View style={styles.currentConditionsView}>
            <CurrentConditions conditions={currentWeatherJSON} />
            <ImageBox source={imageSource} />
            <SuggestionsBox
              weather={currentWeatherJSON}
              currentRatingObject={currentRatingObject}
            />
          </View>
        ) : (
          <>
            <ActivityIndicator size="large" />
            <Text>Loading...</Text>
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100vh",
    backgroundColor: "#ff00ff44",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  header: {
    fontSize: 30,
    textAlign: "center",
    fontWeight: "bold",
  },
  currentConditionsView: {
    flex: 1,
    height: "100vh",
    width: "95%",
    backgroundColor: "#ff00ff44",
    marginVertical: 4,
    justifyContent: "space-evenly",
    textAlign: "center",
  },
  conditionDetails: {
    fontSize: 20,
  },
  image: {
    alignContent: "center",
    marginHorizontal: "auto",
    width: "90%",
    aspectRatio: 1,
    // height: "100px",
  },
  clothingSuggestionsBox: {
    backgroundColor: "#ffffff77",
    borderRadius: 5,
    borderWidth: 2,
    borderColor: "#fff",
    textAlign: "left",
    width: "85%",
    marginHorizontal: "auto",
    padding: "5px",
  },
  scrollView: {
    flex: 1,
    // height: "100vh",
    backgroundColor: "pink",
    alignItems: "center",
    justifyContent: "center",
  },
});
