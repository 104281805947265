import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBSc0ypf3pvvHpoapE-dYtjuJO695oSLVI",
  authDomain: "clothes-picker.firebaseapp.com",
  databaseURL: "https://clothes-picker.firebaseio.com",
  projectId: "clothes-picker",
  storageBucket: "clothes-picker.appspot.com",
  messagingSenderId: "127589977047",
  appId: "1:127589977047:web:75f23005ce7321d36b5992",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
