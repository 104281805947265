import React from "react";
import { View, Text, StyleSheet } from "react-native";

export default function SuggestionsBox(props) {
  const { currentRatingObject, weather } = props;
  return (
    <View>
      <Text style={styles.header}>Clothes-picks:</Text>
      <View style={styles.clothingSuggestionsBox}>
        <Text style={{ textAlign: "center" }}>
          {currentRatingObject?.blurbs || ""}
        </Text>
        <Text style={{ textAlign: "center" }}>
          {weather.current.hasOwnProperty("rain")
            ? `It's raining ☔. Bring an umbrella!`
            : ``}
        </Text>
        <Text style={styles.suggestion}>
          TOP: {currentRatingObject?.tops || ""}.{" "}
        </Text>
        <Text style={styles.suggestion}>
          OUTER: {currentRatingObject?.outers || ""}.{" "}
        </Text>
        <Text style={styles.suggestion}>
          BOTTOM: {currentRatingObject?.bottoms || ""}.{" "}
        </Text>
        <Text style={styles.suggestion}>
          SHOES: {currentRatingObject?.shoes || ""}.{" "}
        </Text>
        <Text style={styles.suggestion}>
          ACCESSORIES: {currentRatingObject?.accessories || ""}.{" "}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ff00ff44",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginVertical: 0,
  },

  clothingSuggestionsBox: {
    backgroundColor: "#ffffff77",
    borderRadius: 5,
    borderWidth: 2,
    borderColor: "#fff",
    textAlign: "left",
    width: "85%",
    marginHorizontal: "auto",
    padding: "5px",
  },
  suggestion: {
    marginVertical: 2,
  },
});
